<template>
  <main v-if="ListProject.status != 'false'" class="col-md-9 ms-sm-auto col-lg-10 px-md-4">
    <div class="container" id="custom-cards">
      <div class="row row-cols-1 row-cols-lg-8 align-items-stretch g-4 py-5">
        <div class="col">
          <h3 class="pb-2 border-bottom text-dark-blue"><i class="fas fa-project-diagram margin-r" aria-hidden="true"></i> Manage members in project</h3>
          <div class="card card-cover overflow-hidden text-white bg-white rounded-5 shadow-lg">
            <div class="d-flex flex-column  p-4 pb-3 text-blue text-shadow-1">
              <div style="display:inline !important">
                <div class="container">
                  <form ref="ProjectForm" @submit.prevent="PostMembersProjectsPost()" class="row g-3 justify-content-center align-items-center">
                    <div class="col-md-12" align="left">
                      <p><i class="fas fa-project-diagram margin-r" aria-hidden="true"></i> {{ListProject.name}}</p>
                    </div>
                    <div class="col-md-12" align="left">
                      <label for="validationDefault03" class="form-label">Members List</label>
                      <Multiselect v-model="MultiSelectMembers.value" v-bind="MultiSelectMembers">
                        <template v-slot:tag="{ option, handleTagRemove, disabled }">
                          <div :style="{'--ms-tag-bg':option.color}"  class="multiselect-tag is-user-project">
                            <img :src="option.icon">
                            <a >{{ option.label }}</a>
                            <span v-if="!disabled" class="multiselect-tag-remove" @mousedown.prevent="handleTagRemove(option, $event)">
                              <span class="multiselect-tag-remove-icon"></span>
                            </span>
                          </div>
                        </template>
                        <template v-slot:option="{ option }">
                          <img class="character-option-icon" :src="option.icon"> {{ option.label }}
                        </template>
                      </Multiselect>
                      <div class="alert alert-warning alert-dismissible fade show mt-3" role="alert">
                        <strong>Tips!</strong><small> You can add members of your organization to a project. The members assigned to a project will be visible as a tag in this list, if you wish to remove a member from the project simply click on the "x" in the tag of the member you wish to remove from the project and save the changes.</small>
                        <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                      </div>
                    </div>
                    <div class="col-md-12" align="left">
                      <label for="validationDefault03" class="form-label">Team List</label>
                      <Multiselect v-model="MultiSelectTeams.value" v-bind="MultiSelectTeams">
                        <template v-slot:tag="{ option, handleTagRemove, disabled }">
                          <div class="multiselect-tag is-user">
                            <!-- <img :src="option.icon"> -->
                            <a :style="{'color':option.color}">{{ option.label }}</a>
                            <span v-if="!disabled" class="multiselect-tag-remove" @mousedown.prevent="handleTagRemove(option, $event)">
                              <span class="multiselect-tag-remove-icon"></span>
                            </span>
                          </div>
                        </template>
                        <template v-slot:option="{ option }">
                          <a>{{ option.label }}</a>
                          <!-- <img class="character-option-icon" :src="option.icon"> {{ option.label }} -->
                        </template>
                      </Multiselect>
                    </div>
                    <div class="col-md-12" align="left">
                      <label for="validationDefault03" class="form-label">Project Manager</label>
                      <Multiselect v-model="MultiSelectProjectManagers.value" v-bind="MultiSelectProjectManagers">
                        <template v-slot:value="{ value }">
                          <div class="multiselect-single-label">
                            <img class="character-label-icon" :src="value.icon"> {{ value.label }}
                          </div>
                        </template>
                        <template v-slot:option="{ option }">
                          <img class="character-option-icon" :src="option.icon"> {{ option.label }}
                        </template>
                      </Multiselect>
                    </div>
                    <!--
                    <div class="col-md-12 mt-3"><div v-if="MembersProjectsPost.status == 'true'" class="msg-block alert alert-success d-flex align-items-center" role="alert"><div>
                          {{$filters.OnlyLetters(MembersProjectsPost.message)}}
                        </div></div><div v-if="MembersProjectsPost.status == 'false'" class="msg-block  alert alert-danger d-flex align-items-center" role="alert"><div>
                          {{$filters.OnlyLetters(MembersProjectsPost.message)}}
                        </div></div></div>
                    -->
                    <div class="d-flex justify-content-end mt-3 m-1">
                      <a class=" btn btn-danger"  style="margin-right: 5px;" @click="$router.go(-1)"> Go Back</a>
                      <button :disabled="Form.submit" class="button-1 btn btn-primary" type="submit">Save</button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
  import Api from '@/services/Request'; 
  import Multiselect from '@vueform/multiselect'

  export default
  {
    name: 'AddMemberProject',
    components: 
    {
      Multiselect,
    },
    data: () => (
    {
      MultiSelectMembers: 
      {
        mode: 'tags',
        value: [],
        closeOnSelect: false,
        options: [],
        searchable: true,
        createTag: false,
        inputType: "email",
        required: false,
        placeholder: "example@domain.com",
        hideSelected: true,
        showOptions : true
      },
      MultiSelectProjectManagers: 
      {
        mode: 'tags',
        value: [],
        closeOnSelect: false,
        options: [],
        searchable: true,
        createTag: false,
        inputType: 'email',
        required: false,
        placeholder: "example@domain.com",
        hideSelected: true,
        showOptions : true
      },
      MultiSelectTeams: 
      {
        mode: 'tags',
        value: [],
        closeOnSelect: false,
        options: [],
        searchable: true,
        createTag: false,
        inputType: 'text',
        required: false,
        placeholder: "My Team",
        hideSelected: true,
        showOptions : true
      },
      ListMembers: 
      {
        status:"false",
        list: {}
      },
      ListTeams: 
      {
        status:"false",
        list: {}
      },
      Form: 
      {
        submit: true
      },
      MembersProjectsPost: 
      {
        id_project:"",
        status:"",
        message:"",
        members: [],
        teams: [],
      },
      ListProject: 
      {
        id:"",
        name:"",
        status:""
      }
    }),
    methods: 
    {
      PostMembersProjectsPost() 
      {
        this.Form.submit = true

        for (var i = 0; i < this.MultiSelectMembers.value.length; i++)
        {  
          this.MembersProjectsPost.members.push(this.MultiSelectMembers.value[i])
        }

        for (var i = 0; i < this.MultiSelectProjectManagers.value.length; i++)
        {  
          this.MembersProjectsPost.members.push(this.MultiSelectProjectManagers.value[i])
        }

        for (var i = 0; i < this.MultiSelectTeams.value.length; i++)
        {  
          this.MembersProjectsPost.teams.push(this.MultiSelectTeams.value[i])
        }

        Api.post("/projects-members/insert",this.MembersProjectsPost).then((result) => 
        {
          if(result.data.status == "true")
          {
            this.MembersProjectsPost.status = result.data.status
            this.MembersProjectsPost.message = result.data.msg;

            this.$moshaToast( ''+this.$filters.OnlyLetters(this.MembersProjectsPost.message)+'', { type: 'default', position:'bottom-right', transition:'zoom' })
         
            setTimeout( () => 
            {
              this.MultiSelectMembers.value = []
              this.MultiSelectMembers.options = []
              this.MultiSelectTeams.value = []
              this.MultiSelectProjectManagers.value = [] 
              this.MultiSelectProjectManagers.options = [] 
              this.MembersProjectsPost.members = []
              this.MembersProjectsPost.teams = []
              this.MembersProjectsPost.message = ""
              this.MembersProjectsPost.status = ""
              this.GetMembers()
              this.Form.submit = false
            }, 5000);
          }
        })    
      },
      GetProject() 
      {
        Api.get('/project/list/' + this.$route.params.id).then((result) => 
        {
          this.ListProject.status = result.data.status

          if(this.ListProject.status == "true")
          {
            this.ListProject.name =  result.data.project[0].name
            this.MembersProjectsPost.id_project =  result.data.project[0].id
          }
        })
      },
      GetTeams() 
      {
        Api.post("/team/list/","").then((result) => 
        {
          this.ListTeams.status = result.data.status

          if(this.ListTeams.status == "true")
          {
            this.ListTeams.list =  result.data.team

            for (var i = 0; i <this.ListTeams.list.length; i++)
            {  
              var options =  {value: this.ListTeams.list[i].id, label: this.ListTeams.list[i].name, icon: this.ListTeams.list[i].picture, color: this.ListTeams.list[i].color}
              this.MultiSelectTeams.options.push(options)  
            }
        
            this.Form.submit = false
          }
        })

        Api.get('/teams-members/list/' + this.$route.params.id).then((result) => 
        {
          if(result.data.status == "true")
          {
            if(result.data.team !="")
            {
              var teams = []
              teams = result.data.team

              for (var i = 0; i < teams.length; i++)
              {  
                var options = teams[i].id
                this.MultiSelectTeams.value.push(options)
              }
            }
          }
        })
      },
      GetMembers() 
      {
        Api.post("/member/list/","").then((result) => 
        {
          this.ListMembers.status = result.data.status

          if(this.ListMembers.status == "true")
          {
            Api.get('/projects-members/list/' + this.$route.params.id).then((result) => 
            {
              if(result.data.status == "true")
              {
                if(result.data.member !="")
                {
                  var members = []
                  members = result.data.member

                  for (var i = 0; i < members.length; i++)
                  {  
                    if(members[i].description == "Worker")
                    {
                      var options = members[i].id_session
                      this.MultiSelectMembers.value.push(options)
                    }
                  
                    if(members[i].description == "Project Manager")
                    {
                      var options = members[i].id_session
                      this.MultiSelectProjectManagers.value.push(options)
                    }
                  }
                }
              }
            })

            this.ListMembers.list =  result.data.member

            for (var i = 0; i <this.ListMembers.list.length; i++)
            {  
              if(this.ListMembers.list[i].description == "Worker")
              {
                var options =  {value: this.ListMembers.list[i].id_session, label: this.ListMembers.list[i].email, icon: this.ListMembers.list[i].picture, color: this.ListMembers.list[i].color}
                this.MultiSelectMembers.options.push(options)
              }
            
              if(this.ListMembers.list[i].description == "Project Manager")
              {
                var options =  {value: this.ListMembers.list[i].id_session, label: this.ListMembers.list[i].email, icon: this.ListMembers.list[i].picture}
                this.MultiSelectProjectManagers.options.push(options)
              }
            }
          }
        })

       
      }
    },
    mounted()
    {
      this.GetProject()
      this.GetMembers()
      this.GetTeams()
    }
  }
</script>

<style>
.multiselect-tag.is-user-project 
{
  padding: 5 px 8 px ;
  border-radius: 22 px ;
  margin: 3 px 3 px 8 px ;
}


.multiselect-tag.is-user-project  img 
{
  width: 25px;
  border-radius: 50%;
  height: 25px;
  margin-right: 8px;
  border: 2px solid #ffffffbf;
}

.multiselect-tag.is-user-project  i:before 
{
  color: #ffffff;
  border-radius: 50%;;
}

.user-image 
{
  margin: 0 6px 0 0;
  border-radius: 50%;
  height: 25px;
}

.character-option-icon 
{
  margin: 0 6px 0 0;
  height: 22px;
}

.character-label-icon 
{
  margin: 0 6px 0 0;
  height: 26px;
}
</style>
